var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-card',{staticClass:"mb-2"},[_c('h5',{staticClass:"display-4"},[_vm._v("Agregar productos o servicios")]),_c('div',{staticClass:"my-2"},[_c('p',{staticClass:"mb-50"},[_vm._v(" Buscar productos o servicios por nombre o código de barras ")]),_c('searchbar-qrcode',{on:{"onChange":_vm.lookupStoreProducts}})],1),_c('div',{staticClass:"my-1"},[_c('h3',{staticClass:"display-5"},[_vm._v(" ¿No encontraste el producto o servicio deseado? 😲 ")]),_c('p',{staticClass:"my-50"},[_vm._v(" Da click en cualquiera de los botones de abajo para registrar un producto o servicio nuevo ")]),_c('b-button',{staticClass:"mr-1 mb-1",attrs:{"to":{
          name: 'new-store-product',
          params: { id: _vm.$route.params.id, type: 'product' },
        }}},[_vm._v(" Registrar producto nuevo ")]),_c('b-button',{staticClass:"mr-1 mb-1",attrs:{"to":{
          name: 'new-store-product',
          params: { id: _vm.$route.params.id, type: 'service' },
        }}},[_vm._v(" Registrar servicio nuevo ")])],1),(_vm.loading)?_c('ul',{staticClass:"list"},_vm._l((10),function(n){return _c('b-skeleton-img',{attrs:{"height":"120px","no-aspect":""}})}),1):_c('ul',{staticClass:"list"},_vm._l((_vm.products),function(product){return _c('li',{key:product.id,staticClass:"item py-50 px-1 shadow-sm border mb-1",attrs:{"no-body":""}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"pr-1"},[_c('b-img',{attrs:{"src":product.logo,"alt":((product.name) + "-" + (product.id)),"width":"120"}})],1),_c('div',{staticClass:"item-name"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('p',{staticClass:"item-rating"},[_vm._v(" "+_vm._s(product.variant)+" ")]),_c('div',_vm._l((product.categories_names),function(cat){return _c('b-badge',{key:("cat-" + cat)},[_vm._v(" "+_vm._s(cat)+" ")])}),1)])]),_c('div',{staticClass:"item-options text-center"},[_c('b-button',{staticClass:"mt-1 remove-wishlist",attrs:{"variant":"success","to":{
              name: 'add-store-product',
              params: { id: _vm.$route.params.id, product_id: product.id },
            }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Agregar")])],1)],1)])}),0),_c('div',{staticClass:"mt-4"},[(_vm.pagination.total_objects > _vm.pagination.per_page)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"handlePagination":_vm.handlePagination,"hideEntriesPerPage":true}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }