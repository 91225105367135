<template>
  <b-container>
    <!-- Products search -->
    <b-card class="mb-2">
      <h5 class="display-4">Agregar productos o servicios</h5>

      <div class="my-2">
        <p class="mb-50">
          Buscar productos o servicios por nombre o código de barras
        </p>
        <searchbar-qrcode @onChange="lookupStoreProducts" />
      </div>

      <div class="my-1">
        <h3 class="display-5">
          ¿No encontraste el producto o servicio deseado? 😲
        </h3>
        <p class="my-50">
          Da click en cualquiera de los botones de abajo para registrar un
          producto o servicio nuevo
        </p>
        <b-button
          class="mr-1 mb-1"
          :to="{
            name: 'new-store-product',
            params: { id: $route.params.id, type: 'product' },
          }"
        >
          Registrar producto nuevo
        </b-button>
        <b-button
          class="mr-1 mb-1"
          :to="{
            name: 'new-store-product',
            params: { id: $route.params.id, type: 'service' },
          }"
        >
          Registrar servicio nuevo
        </b-button>
      </div>
      <ul v-if="loading" class="list">
        <b-skeleton-img v-for="n in 10" height="120px" no-aspect />
      </ul>

      <ul v-else class="list">
        <li
          v-for="product in products"
          :key="product.id"
          class="item py-50 px-1 shadow-sm border mb-1"
          no-body
        >
          <div class="d-flex align-items-center">
            <div class="pr-1">
              <b-img
                :src="product.logo"
                :alt="`${product.name}-${product.id}`"
                width="120"
              />
            </div>

            <div class="item-name">
              <h6 class="mb-0">
                {{ product.name }}
              </h6>
              <p class="item-rating">
                {{ product.variant }}
              </p>
              <div>
                <b-badge
                  v-for="cat in product.categories_names"
                  :key="`cat-${cat}`"
                >
                  {{ cat }}
                </b-badge>
              </div>
            </div>
          </div>

          <div class="item-options text-center">
            <b-button
              variant="success"
              class="mt-1 remove-wishlist"
              :to="{
                name: 'add-store-product',
                params: { id: $route.params.id, product_id: product.id },
              }"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span>Agregar</span>
            </b-button>
          </div>
        </li>
      </ul>

      <div class="mt-4">
        <Pagination
          v-if="pagination.total_objects > pagination.per_page"
          :pagination="pagination"
          :handlePagination="handlePagination"
          :hideEntriesPerPage="true"
        />
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { debounce } from "lodash"
import { mapGetters, mapActions } from "vuex"

import Pagination from "@/@core/components/Pagination.vue"
import SearchbarQrcode from "@/@core/components/searchbar-qrcode.vue"

export default {
  components: {
    Pagination,
    SearchbarQrcode,
  },
  data() {
    return {
      isSku: false,
      searchQuery: "",
      loading: false,
    }
  },
  computed: {
    ...mapGetters("products", ["products", "pagination"]),
  },
  mounted() {
    document.addEventListener("keydown", () => {
      document.getElementById("sku").focus()
    })

    this.getProducts()
  },
  methods: {
    ...mapActions("products", ["fetchProducts"]),

    lookupStoreProducts: debounce(function searchQuery(query) {
      this.isSku = false

      if (!query || query === "") {
        this.searchQuery = ""
        this.getProducts()
        return
      }

      if (/^\d*$/.test(query)) {
        const barcodeWithOutLastDigit = query.substring(0, query.length - 1)
        if (navigator.vibrate) {
          navigator.vibrate(200)
        }

        this.isSku = true
        this.searchQuery = Number(barcodeWithOutLastDigit)
      } else {
        this.searchQuery = query
      }

      this.getProducts({
        page: 1,
      })
    }, 250),
    handlePagination({ page }) {
      this.getProducts({
        page,
      })
    },
    getProducts(
      { page, ...filters } = {
        page: 1,
        filters: {},
      }
    ) {
      this.loading = true

      this.fetchProducts({
        ...filters,
        by_name: this.isSku ? null : this.searchQuery,
        by_sku: this.isSku ? this.searchQuery : null,
        meta: {
          pagination: {
            page: page || 1,
          },
        },
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.list {
  cursor: pointer;
  display: grid;
  gap: 1rem;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-radius: 0.5rem;

    .item-name {
      h6 {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
  }

  .item:hover {
    box-shadow: 0 0 1rem 0 rgba(136, 152, 170, 0.15);
    transition: box-shadow 0.2s ease-in-out;
    transform: translateY(-5px);
    transition: transform 0.2s ease-in-out;
  }
}
</style>
